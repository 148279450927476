import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useStore } from "@/store/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "AppRouter",
    component: () => import("@/layouts/AppLayout.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "/",
        redirect: "/dashboard",
      },
      {
        path: "/dashboard",
        name: "home",
        component: () => import("@/views/home/HomeView.vue"),
      },
      {
        path: "/relatorio",
        name: "Relatorio",
        component: () => import("@/views/relatorio/RelatorioView.vue"),
      },
      {
        path: "/chats",
        name: "chats",
        component: () => import("@/components/chat-room/ChatRoom.vue"),
      },
      {
        path: "/chatbot",
        name: "chatbotRouter",
        component: () => import("@/views/chatbot/RouterView.vue"),
        children: [
          {
            path: "",
            name: "chatbotView",
            component: () => import("@/views/chatbot/ChatbotView.vue"),
          },
          {
            path: "/chatbot/:id",
            name: "chatbotEdit",
            component: () => import("@/views/chatbot/ChatbotEditView.vue"),
          },
        ],
      },
      {
        path: "/assistente-ia",
        name: "assistenteIARouter",
        component: () => import("@/views/assistente-ia/RouterView.vue"),
        children: [
          {
            path: "",
            name: "AssistenteView",
            component: () => import("@/views/assistente-ia/AssistenteView.vue"),
          },
          {
            path: "/assistente-ia/:id",
            name: "AssistenteEditView",
            component: () => import("@/views/assistente-ia/AssistenteEditView.vue"),
          },
        ],
      },
      {
        path: "/canal/:codCanal?",
        name: "canal",
        component: () => import("@/views/canal/ConexaoView.vue"),
      },
      {
        path: "/playground",
        name: "playground",
        component: () => import("@/views/playground/PlaygroundView.vue"),
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import("@/views/ajuda/AjudaView.vue"),
      },
      {
        path: "/campanhas",
        name: "campanhas",
        component: () => import("@/views/campanha/CampanhaView.vue"),
      },
      {
        path: "/transmissao",
        name: "transmissaoRouter",
        component: () => import("@/views/transmissao/RouterView.vue"),
        children: [
          {
            path: "",
            name: "TransmissaoView",
            component: () => import("@/views/transmissao/TransmissaoView.vue"),
          },
          {
            path: "/transmissao/:id",
            name: "TransmissaoEditView",
            component: () => import("@/views/transmissao/TransmissaoEditView.vue"),
          },
        ],
      },
      {
        path: "/meus-dados",
        name: "meusDados",
        component: () => import("@/views/meus-dados/MeusDadosView.vue"),
      },
      {
        path: "/admin",
        name: "admin",
        component: () => import("@/views/admin/AdminView.vue"),
      },
      {
        path: "/contatos",
        name: "contatos",
        component: () => import("@/views/contato/ContatoView.vue"),
      },
      {
        path: "/atendimentos",
        name: "atendimentos",
        component: () => import("@/views/atendimento/AtendimentosView.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "LoginRouter",
    component: () => import("@/layouts/LoginLayout.vue"),
    meta: {
      auth: false,
    },
    children: [
      {
        path: "",
        name: "LoginPage",
        component: () => import("@/views/login/LoginView.vue"),
      },
      {
        path: "cadastro",
        name: "CadastroView",
        component: () => import("@/views/login/cadastro/CadastroView.vue"),
      },
      {
        path: "recuperacao-senha",
        name: "EsqueciSenhaView",
        component: () => import("@/views/login/esqueci-senha/EsqueciSenhaView.vue"),
      },
      {
        path: "confirmacao-recuperacao-senha/:id",
        name: "EsqueciSenhaConfirmacaoView",
        component: () => import("@/views/login/esqueci-senha-confirmacao/EsqueciSenhaConfirmacaoView.vue"),
      },
      {
        path: "definir-senha/:id",
        name: "DefinirSenhaView",
        component: () => import("@/views/login/definir-senha/DefinirSenhaView.vue"),
      },
    ],
  },
  {
    path: "/unsubscribe-broadcastlist/:token",
    name: "UnsubscribeBroadcastListRouter",
    component: () => import("@/layouts/PublicLayout.vue"),
    meta: {
      auth: false,
    },
    children: [
      {
        path: "",
        name: "unsubscribeBroadcastList",
        component: () => import("@/views/transmissao/UnsubscribeBroadcastList.vue"),
      },
    ],
  },
  {
    path: "/termo-privacidade",
    name: "PublicRouter",
    component: () => import("@/layouts/PublicLayout.vue"),
    meta: {
      auth: false,
    },
    children: [
      {
        path: "",
        name: "termoPrivacidade",
        component: () => import("@/views/termo-privacidade/TermoPrivacidadeView.vue"),
      },
    ],
  },
  {
    path: "/termo-uso",
    name: "TermoPublicRouter",
    component: () => import("@/layouts/PublicLayout.vue"),
    meta: {
      auth: false,
    },
    children: [
      {
        path: "",
        name: "termoUso",
        component: () => import("@/views/termo-de-uso/TermoUsoView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {

  const store = useStore()

  if (to.matched.some((record) => record.meta.auth)) {
    if (store.usuarioEstalogado) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
